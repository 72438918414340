import React, { useState, useEffect, useRef } from "react";
import { ModalBody, ModalHeader, Button, Modal, Label, FormGroup, Input, FormFeedback, ListGroup, ListGroupItem, Spinner, Row, Col } from "reactstrap";
import Asterisk from "../../components/commons/Asterisk";
import { LoadingState, searchSelfReportedMedication, selectSelfReportedMedication } from "../../core/service/services";
import { toastError } from "../../App";
import { IMedicationSearchItem } from "../book-appointment/AppointmentModels";
import { IDoseSpotPrescription } from "../../redux/types/interfaces/medicalProfileInterfaces";

export interface ISelfReportedMedication {
  name: string;
  strength: string[]
  dispensableDrugId: number
}

export interface IProps {
  toggleModal: () => void;
  modalIsOpen: boolean;
  addSelfReportedMedication: (medication: IDoseSpotPrescription) => void
}

export const SelfReportedMedicationsModal: React.FC<IProps> = props => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [medicationSearchItems, setMedicationSearchItems] = useState<IMedicationSearchItem[]>([]);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.nuteral);
  const [chosenMedication, setChosenMedication] = useState<IMedicationSearchItem>()

  useEffect(() => {
    setOpen(props.modalIsOpen)
  }, [props.modalIsOpen])
  
  useEffect(()=>{
    if(chosenMedication && chosenMedication.strength==undefined){ //selects the med if there is no strength
      selectSelfReportedMedication(chosenMedication.dispensableDrugId, chosenMedication.nameWithRouteDoseForm).then(res => {
        props.addSelfReportedMedication(res)
        setMedicationSearchItems([])
        setChosenMedication(undefined)
      }).catch(err => console.log(err))
    }
  },[chosenMedication])

  const inputRef = useRef<HTMLInputElement>(null);

  const onInputNameChange = (e: any) => {
    let name = e.target.value;
    inputRef.current && (inputRef.current.value = name);
    if (name.length >= 3) {
      setChosenMedication(undefined)
      setIsLoading(LoadingState.loading);
      searchSelfReportedMedication(e.target.value)
        .then((response) => {
          setIsLoading(LoadingState.succeed);
          setMedicationSearchItems(response.items);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(LoadingState.failed);
        });
    }
  };

  const onMedicationSelected = (item: IMedicationSearchItem) => {
    setChosenMedication(item)
  }

  const onStrengthSelected = (strength: string) => {
    if (chosenMedication) {
      selectSelfReportedMedication(chosenMedication.dispensableDrugId, chosenMedication.nameWithRouteDoseForm).then(res => {
        res.Strength = strength
        props.addSelfReportedMedication(res)
        setMedicationSearchItems([])
        setChosenMedication(undefined)

      }).catch(err => console.log(err))
    }
  }

  const renderResults = () => {
    if (isLoading === LoadingState.failed) {
      toastError("No matching found — try another name!")
    } else {
      return (
        medicationSearchItems.length > 0 && (
          <ListGroup className="pt-2" style={{ maxHeight: "20rem", overflow: "auto" }}>
            {medicationSearchItems.map(item => (
              <ListGroupItem  color="primary" action onClick={() => onMedicationSelected(item)}>
                {item.nameWithRouteDoseForm}
              </ListGroupItem>
            ))}
          </ListGroup>
        )
      )
    }
    return null;
  }

  const renderStrengthOptions = () => {
    if (chosenMedication && chosenMedication.strength!==undefined) {
      const strengthDoses = chosenMedication.strength.split(' '); // Split the string into individual strength-dose pairs
      const strengths = strengthDoses[0].split('-')
      const units = strengthDoses[1].split('-')
      var unit = ''

      return (
        <Row>
          <Col md="12">{"Click on the strength for " + chosenMedication?.nameWithRouteDoseForm + ":"}</Col>
            <Col md="6" lg="6">
            {strengths.map((strength, index) => {
            if(units[index]){
              unit = units[index]
            }
            return <Button key={index} onClick={() => { onStrengthSelected(`${strength}-${unit}`)}} color="link">{strength}-{unit}</Button>
            })}
            </Col>
       
        </Row>
      )
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={props.toggleModal}>
      <ModalHeader toggle={props.toggleModal}>
        <h5 id="selfReportedMedicationsModal" className="modal-title">Please report your active medications</h5>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg="10" xl="10">
           <FormGroup>
              <Label for="medicationName">Medication Name</Label>
              <Asterisk />
              <Input type="text" innerRef={inputRef} name="name" id="medicationName"
                placeholder="Search for your medication" onChange={onInputNameChange} autoComplete='off' required/>
              {renderResults()}
              {renderStrengthOptions()}
              <FormFeedback>Name needs to be filled out</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="2" xl="2">
          {isLoading === LoadingState.loading && <Spinner color="primary" className="mt-4"/>}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
